import * as React from "react";
import { Box } from "@mui/material";

export function YouTubeEmbed(props: any) {
  const videoId = props.node.url.split("?v=")[1];
  const embeddedUrl = `https://youtube.com/embed/${videoId}`;
  return (
    <Box display="flex" justifyContent="center">
      <iframe width="720px" height="405px" src={embeddedUrl}></iframe>
    </Box>
  );
}
