import { Figure } from "./Figure";
import { YouTubeEmbed } from "./YouTubeEmbed";
import * as React from "react";
import { Link } from "gatsby";

const CreateLink = (blank, href, internal, children) => {
  if (internal) {
    return blank ? (
      <Link to={href} target="_blank" rel="noopener" rel="noreferrer">
        {children}
      </Link>
    ) : (
      <Link to={href}>{children}</Link>
    );
  } else {
    return blank ? (
      <a href={href} target="_blank" rel="noopener" rel="noreferrer">
        {children}
      </a>
    ) : (
      <a href={href}>{children}</a>
    );
  }
};

const serializers = {
  types: {
    figure: Figure,
    youtubeEmbed: YouTubeEmbed,
  },
  marks: {
    link: ({ mark, children }) => {
      const { blank, href, internal } = mark;
      return CreateLink(blank, href, internal, children);
    },
  },
};

export default serializers;
