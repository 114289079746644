import * as React from "react";
import BaseBlockContent from "@sanity/block-content-to-react";
import { Markdown } from "../../data/types/Markdown";
import serializers from "./serializers";

export interface IMarkdownBlockProps {
  data: Markdown;
}

export function MarkdownBlock(props: IMarkdownBlockProps) {
  return <BaseBlockContent blocks={props.data} serializers={serializers} />;
}
