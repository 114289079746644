import * as React from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../../client-config";
import { GatsbyImage } from "gatsby-plugin-image";
import { Typography } from "@mui/material";

export function Figure(props: any) {
  const imageData = getGatsbyImageData(
    props.node.asset._ref,
    { width: 675 },
    clientConfig.sanity
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div>
        <GatsbyImage image={imageData} alt={props.node.alt} />
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: "center",
            marginTop: "8px",
          }}
        >
          {props.node.caption}
        </Typography>
      </div>
    </div>
  );
}
